import { css } from 'lit'

// Shared styles for the top navigation desktop and mobile
export const styles = css`
  :host {
    --tml-navigation-color: hsla(0, 100%, 100%, 1);
    z-index: 9999;
    position: fixed;
    width: 100%;
  }

  .visually-hidden:not(:focus):not(:active) {
    position: absolute;
    width: 1px;
    height: 1px;
    white-space: nowrap;
    overflow: hidden;
    clip: rect(0 0 0 0);
    clip-path: inset(50%);
  }

  a,
  span,
  button {
    color: var(--tml-navigation-color);
    font-size: 14px;
    font-weight: 300;
    font-family: europa, sans-serif;
    letter-spacing: 1.2px;
    text-transform: uppercase;
    text-decoration: none;
  }

  a {
    transition: all 0.3s ease-in-out;
  }

  a:hover {
    text-underline-offset: 4px;
    text-decoration: underline;
  }

  ul {
    display: flex;
    margin: 0;
    padding: 0;
    list-style: none;
  }

  button {
    background: none;
    border: none;
    cursor: pointer;
  }

  .caret {
    display: inline-block;
    width: 12px;
    height: 12px;
    mask-position: 50%;
    mask-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='666 521 11 8'%3E%3Cpath fill='%23fff' fill-rule='evenodd' d='m671.75 525.76 3.28-3.28.57-.57 1.13 1.13-.56.57-3.85 3.85-.57.56-.28-.28-.29-.28-3.85-3.86-.56-.56 1.13-1.13.56.56z'/%3E%3C/svg%3E");
    -webkit-mask-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='666 521 11 8'%3E%3Cpath fill='%23fff' fill-rule='evenodd' d='m671.75 525.76 3.28-3.28.57-.57 1.13 1.13-.56.57-3.85 3.85-.57.56-.28-.28-.29-.28-3.85-3.86-.56-.56 1.13-1.13.56.56z'/%3E%3C/svg%3E");
    mask-repeat: no-repeat;
    -webkit-mask-repeat: no-repeat;
    pointer-events: none;
    transition: transform 0.3s ease-in-out;
    background-color: var(--tml-navigation-color);
  }

  .cross {
    display: inline-block;
    width: 24px;
    height: 24px;
    background-position: 50%;
    mask-repeat: no-repeat;
    mask-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='%23fff' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' class='icon icon-tabler icons-tabler-outline icon-tabler-x'%3E%3Cpath d='M0 0h24v24H0z' stroke='none'/%3E%3Cpath d='M18 6 6 18M6 6l12 12'/%3E%3C/svg%3E");
    -webkit-mask-repeat: no-repeat;
    -webkit-mask-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='%23fff' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' class='icon icon-tabler icons-tabler-outline icon-tabler-x'%3E%3Cpath d='M0 0h24v24H0z' stroke='none'/%3E%3Cpath d='M18 6 6 18M6 6l12 12'/%3E%3C/svg%3E");
    background-color: var(--tml-navigation-color);
    pointer-events: none;
  }

  .hamburger {
    display: inline-block;
    width: 24px;
    height: 24px;
    background-position: 50%;
    mask-repeat: no-repeat;
    mask-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='%23fff' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' class='icon icon-tabler icons-tabler-outline icon-tabler-menu-2'%3E%3Cpath d='M0 0h24v24H0z' stroke='none'/%3E%3Cpath d='M4 6h16M4 12h16M4 18h16'/%3E%3C/svg%3E");
    -webkit-mask-repeat: no-repeat;
    -webkit-mask-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='%23fff' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' class='icon icon-tabler icons-tabler-outline icon-tabler-menu-2'%3E%3Cpath d='M0 0h24v24H0z' stroke='none'/%3E%3Cpath d='M4 6h16M4 12h16M4 18h16'/%3E%3C/svg%3E");
    background-color: var(--tml-navigation-color);
    pointer-events: none;
  }
`
