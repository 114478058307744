import { css } from 'lit'

export const mobileNavigationStyles = css`
  .mobile-main .bar {
    z-index: 2;
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-inline: 16px;
    padding-block: 16px;
    background-color: var(
      --tml-navigation-background-color,
      hsla(0, 0%, 0%, 1)
    );
  }

  .mobile-main-left {
    flex-grow: 1;
    flex-basis: 0;
    display: flex;
    gap: 16px;
    align-items: center;
    padding: 0;
  }

  .mobile-main-right {
    flex-grow: 1;
    flex-basis: 0;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 0;
  }

  .mobile-main .tml-logo {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 32px;
    transition: transform 0.3s ease-in-out;
  }

  .mobile-main .tml-logo svg {
    fill: var(--tml-navigation-color);
    height: 32px;
    width: 32px;
  }

  .mobile-main .tml-account-link {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 32px;
    height: 32px;
  }
  .mobile-main .tml-account-link svg {
    fill: var(--tml-navigation-color);
  }
  .mobile-main .home-logo svg {
    stroke: var(--tml-navigation-color);
  }
  .mobile-main .home-logo svg g {
    stroke-width: 1.4px;
  }
  .mobile-main .profile-logo svg {
    width: 28px;
    height: 28px;
  }

  .mobile-main .main-nav {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    gap: 32px;
    height: 64px;
    width: 100%;
    transition: transform 0.5s ease-in-out;
  }

  .mobile-main .scrollaway {
    transform: translateY(-140px);
  }

  .mobile-main .language-picker {
    z-index: 2;
    position: fixed;
    display: flex;
    justify-content: center;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 16px 0;
    transition: opacity 0.5s ease-in-out;
    backdrop-filter: blur(16px);
  }

  .mobile-main .language-picker a {
    color: #ffffff;
    padding-inline: 16px;
    cursor: pointer;
  }

  .language-picker a + a {
    border-left: 1px solid #ffffff;
  }

  .hidden {
    opacity: 0;
  }

  .underline {
    text-underline-offset: 4px;
    text-decoration: underline;
  }

  .mobile-main .main-nav a {
    display: block;
    padding: 8px 16px;
    background-color: hsl(196, 16%, 19%);
    border-radius: 9999px;
    min-width: 64px;
    text-align: center;
    font-weight: 700;
  }

  .mobile-main .navigation {
    z-index: 1;
    position: absolute;
    height: 100dvh;
    top: 64px;
    left: 0;
    right: 0;
    padding-inline: 16px;
    padding-block: 0 16px;
    overflow: hidden;
    overflow-y: scroll;
    transition: transform 0.3s ease-in-out;
    scrollbar-width: none;
  }

  .mobile-main .navigation::-webkit-scrollbar {
    display: none;
  }

  .mobile-main .navigation .background-filter {
    z-index: -1;
    position: fixed;
    inset: 0;
    width: 100%;
    -webkit-backdrop-filter: brightness(60%) blur(16px);
    backdrop-filter: brightness(60%) blur(16px);
  }

  .mobile-main .navigation[data-visible='false'] {
    transform: translateY(calc(-100% - 64px));
  }

  .mobile-main .top-navigation {
    display: flex;
    flex-direction: column;
    font-size: 16px;
    transition: transform 0.3s ease-in-out;
  }

  .mobile-main .top-navigation button,
  .mobile-main .top-navigation a {
    color: #ffffff;
    display: block;
    width: 100%;
    padding-block: 16px;
    padding-inline: 0;
    text-align: start;
    font-size: 16px;
    font-weight: 700;
  }

  .mobile-main .top-navigation [data-visible='false'] {
    opacity: 0;
    visibility: hidden;
    pointer-events: none;
    /*
    * Hide subitems only after they are moved from the screen
    * we hide the items to prevent them showing up when they slide in
    * adding this transition this state makes sure it only triggers when the items are removed
    */
    transition: all 0s ease-in-out 0.3s;
  }

  .mobile-main .top-navigation .caret {
    background-color: #ffffff;
    width: 10px;
    transform: rotate(-90deg);
  }

  .mobile-main .sub-navigation {
    display: flex;
    flex-direction: column;
    height: 0px;
    padding-inline: 16px;
    top: 0;
    overflow: hidden;
    transition: height 0.5s ease-in-out;
  }

  .mobile-main .sub-navigation ul {
    display: flex;
    flex-direction: column;
    max-height: 100%;
    height: 100%;
  }

  .mobile-main .sub-navigation .link-button {
    padding-inline: 16px;
  }

  .mobile-main .sub-sub-navigation {
    position: absolute;
    display: flex;
    flex-direction: column;
    width: calc(100vw - 32px);
    left: 100%;
    padding-inline: 16px;
    top: 0;
  }

  .mobile-main .sub-sub-navigation .back-button {
    width: 100%;
    display: flex;
    align-items: center;
    gap: 8px;
    padding-block: 16px;
    font-size: 12px;
    font-weight: 300;
  }

  .mobile-main .sub-sub-navigation h1 {
    position: relative;
    margin: 8px 0 12px;
    color: white;
    font-family: europa, sans-serif;
    font-weight: 700;
    text-transform: uppercase;
  }
  .mobile-main .sub-sub-navigation h1::after {
    content: '';
    position: absolute;
    bottom: -4px;
    left: 0;
    width: 80px;
    height: 1px;
    background-color: white;
  }

  .mobile-main .sub-sub-navigation ul {
    display: flex;
    flex-direction: column;
  }

  .mobile-main .sub-sub-navigation .link-button {
    padding-inline: 16px;
  }
`
