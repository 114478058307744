import { html, LitElement, nothing } from 'lit'
import { property, state } from 'lit/decorators.js'
import { styles } from './styles'
import { TmlNavigationDocumentData } from '@repo/prismic-data/prismicio-types'
import { desktopNavigationStyles } from './DesktopNavigation.styles'
import DesktopNavigation from './DesktopNavigation'
import { mobileNavigationStyles } from './MobileNavigation.styles'
import MobileNavigation from './MobileNavigation'
import { booleanConverter } from '../../utils/converters'
import { getUserDataLight } from 'https://login.tomorrowland.com/lib/tml-sso.es.js'

declare global {
  interface HTMLElementTagNameMap {
    'tml-navigation': TMLNavigation
  }
}

// Event listeners
const mediaQuery = window.matchMedia('(min-width: 992px)')

mediaQuery.addEventListener('change', (e) => {
  document.querySelector('tml-navigation')?.setIsDesktop(e.matches)
})

export class TMLNavigation extends LitElement {
  static styles = [styles, desktopNavigationStyles, mobileNavigationStyles]

  @state()
  private _isDesktop = mediaQuery.matches

  @state()
  name: string | undefined

  @state()
  _openDesktopMenuIndex: number | undefined

  @state()
  _openMobileMenu = false

  @state()
  _openMobileMenuI: number | undefined

  @state()
  _openMobileMenuII: number | undefined

  @state()
  _openMobileMenuIII: number | undefined

  @state()
  width?: number

  @state()
  closestNavUrl?: string

  @state()
  closestNavId?: string

  @property()
  language?: string

  @property()
  private _desktopNavigation = new DesktopNavigation(this)

  @property()
  private _mobileNavigation = new MobileNavigation(this)

  @property({ converter: booleanConverter })
  loginButton = true

  @property({ converter: booleanConverter })
  festivalButtons?: boolean

  @property({ converter: booleanConverter })
  inverseLogo?: boolean

  @property()
  color?: string

  // Prismic loader data
  @property({ attribute: false })
  data: Record<string, TmlNavigationDocumentData> | undefined

  @property()
  ssoBaseUrl?: string

  constructor() {
    super()
    this.setIsDesktop(mediaQuery.matches)
  }

  async firstUpdated() {
    this.name = await this._fetchUser()
  }

  async _fetchUser() {
    try {
      const result = await getUserDataLight(this.ssoBaseUrl || undefined)

      const displayName = result?.user.company || result?.user.firstName
      if (!displayName) return

      return displayName.length > 10
        ? `${displayName.substring(0, 8)}...`
        : displayName
    } catch (error) {
      console.error('Error fetching user:', error)
    }
  }

  updated(): void {
    const slot = this.shadowRoot?.querySelector(
      'slot[name="language-picker"]',
    ) as HTMLSlotElement
    const slottedElements = slot?.assignedElements()

    if (slottedElements && slottedElements.length > 0) {
      const webComponent = slottedElements[0]
      const value = this._isDesktop ? 'false' : 'true'
      if (webComponent?.getAttribute('mobile') === value) {
        return
      }
      webComponent?.setAttribute('mobile', this._isDesktop ? 'false' : 'true')
    }

    const currentValue = getComputedStyle(this).getPropertyValue(
      '--tml-navigation-background-color',
    )
    if (currentValue !== this.color) {
      this.color = currentValue
    }
  }

  loadLanguage(locale: string) {
    this.language = locale
  }

  setIsDesktop(matches: boolean) {
    this._isDesktop = matches
  }

  render() {
    const data = this.data?.[this.language || 'en-us']
    if (!data) return nothing

    return html` <nav>
      ${this._isDesktop
        ? this._desktopNavigation.render(data)
        : this._mobileNavigation.render(data)}
    </nav>`
  }
}

// Define only if not already exists
if (!window.customElements.get('tml-navigation')) {
  window.customElements.define('tml-navigation', TMLNavigation)
}
